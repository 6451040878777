<template>
  <div class="wrapper">
    <Main id="main" data-header-class="header-class-home"/>
    <WhatWeAre id="whoWeAre" data-header-class="header-class-whoWeAre"/>
    <Vision id="vision" data-header-class="header-class-vision"/>
    <WhatWeDo id="whatWeDo" data-header-class="header-class-whatWeDo"/>
    <Benefits id="benefits" data-header-class="header-class-benefits"/>
    <Contacts id="contacts" data-header-class="header-class-contacts" :open-popup="openPopup"/>
  </div>
</template>

<script>
// @ is an alias to /src

import Main from "@/components/main/Main.vue";
import WhatWeAre from "@/components/whoWeAre/WhatWeAre.vue";
import Vision from "@/components/vision/Vision.vue";
import WhatWeDo from "@/components/whatWeDo/WhatWeDo.vue";
import Benefits from "@/components/benefits/Benefits.vue";
import Contacts from "@/components/contacts/Contacts.vue";

export default {
  name: 'HomeView',
  components: {Contacts, Benefits, Vision, WhatWeDo, WhatWeAre, Main},
  methods: {
    openPopup() {
      this.$emit('show-popup'); // Emit the 'show-popup' event from HomeView
    }
  }
}
</script>

<style lang="scss">
.wrapper {
  margin-top: -101px;
  scroll-behavior: smooth;

  @media (max-width: 1440px) {
    margin-top: -81px;
  }

  @media (max-width: 1024px) {
    margin-top: -71px;
  }
}
</style>
