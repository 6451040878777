<template>
<div class="contacts">
  <div class="container">
    <div class="contacts__content">
      <div class="contacts__left">
        <div class="contacts__data">
          <h1 class="contacts__title">{{ $t('contacts.contacts') }}</h1>
          <div class="contacts__info">
            <div>
              <p>{{ $t('contacts.callUs') }}</p>
              <a href="tel:+971551234455">+971 55 123 44 55</a>
            </div>
            <div>
              <p>{{ $t('contacts.emailUs') }}</p>
              <a href="mailto:info@vnf-group.com">info@vnf-group.com</a>
            </div>
          </div>
          <p class="contacts__info-separator">{{ $t('contacts.or') }}</p>
          <Button class="btn_blue" aria-label="Send a request" @click="openPopup">{{ $t('contacts.sendRequest') }}</Button>
        </div>
        <div class="contacts__license">
          VNF Vacation Homes L.L.C. <br>
          {{ $t('contacts.license') }} 1147042 <br>
          Dubai Marina, Al Habtoor Business Tower, Office 1001
        </div>
      </div>
      <div class="contacts__separator"></div>
      <a href="https://goo.gl/maps/xf3929J5MuDVSt3Z6?coh=178571&entry=tt" target="_blank" class="contacts__right">
        <img style="width: 100%;" decoding="async" v-if="$i18n.locale === 'en'"
             src="~@/assets/images/map-en.webp" alt="Map"
             srcset="~@/assets/images/map-en.webp 775w,
                     ~@/assets/images/map-en-375.webp 375w"
             sizes="(max-width: 450px) 375px,
                    775px">
        <img style="width: 100%;" decoding="async" v-else
             src="~@/assets/images/map-ru.webp" alt="Map"
             srcset="~@/assets/images/map-ru.webp 775w,
                     ~@/assets/images/map-ru-375.webp 375w"
             sizes="(max-width: 450px) 375px,
                    775px">
      </a>
    </div>
  </div>
</div>
</template>

<script>
import Button from '@/components/blocks/Button.vue'

export default {
  name: 'Contacts',
  components: { Button },
  props: ['openPopup']
}
</script>

<style lang="scss">
.contacts {
  padding: 20px 0;
  min-height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @media (max-width: 768px) {
    padding: 90px 0 20px 0;
  }

  @media (max-width: 450px) {
    padding: 90px 0 0 0;
  }
  .container {
    @media (max-width: 450px) {
      padding: 0;
    }
  }
  &__content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media (max-width: 910px) {
      flex-direction: column;
    }
  }

  &__left {
    max-width: 360px;
    width: 100%;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    grid-gap: 40px;

    @media (max-width: 910px) {
      max-width: 100%;
      padding: 0 20px 40px 20px;
    }
  }

  &__data {
    display: flex;
    flex-direction: column;
    grid-gap: 40px;

    @media (max-width: 1440px) {
      grid-gap: 20px;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 64px;
    line-height: 130%;
    color: #313E47;

    @media (max-width: 1440px) {
      font-size: 40px;
    }
    @media (max-width: 768px) {
      font-size: 40px;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    grid-gap: 20px;

    >div {
      display: flex;
      flex-direction: column;
      grid-gap: 5px;

      p {
        font-size: 20px;
        line-height: 26px;
        color: #313E47;
      }

      a {
        font-weight: 700;
        font-size: 30px;
        line-height: 39px;
        color: #313E47;
      }
    }
  }

  &__info-separator {
    font-weight: 700;
    font-size: 30px;
    line-height: 39px;
    text-align: center;
    color: #313E47;
  }

  &__license {
    font-size: 24px;
    line-height: 130%;
    color: #313E47;
  }

  &__separator {
    width: 1px;
    border-left: 1px solid #313E47;

    @media (max-width: 910px) {
      border-left: 0;
      border-bottom: 1px solid #313E47;
      width: 100%;
      height: 1px;
    }
  }

  &__right {
    position: relative;
    padding-left: 20px;
    max-width: 775px;
    width: 100%;

    img {
      width: 100%;
    }

    @media (max-width: 910px) {
      max-width: 100%;
      padding-left: 0;
      padding-top: 40px;
    }
  }
}

</style>
