<template>
<div class="wwa">
  <div class="container">
    <div class="wwa__content">
      <div class="wwa__left">
        <h1 class="wwa__title" v-html="$t('whoWeAre.whoWeAre')"></h1>
        <div class="wwa__subtitle">
          <p>{{ $t('whoWeAre.vnfIsATeamOf') }}</p>
          <p>{{ $t('whoWeAre.ourSkillsetCovers') }}</p>
        </div>
      </div>
      <span class="wwa__separator"></span>
      <div class="wwa__right">
        <h2 v-html="$t('whoWeAre.yearsOfExperience')"></h2>
        <div>
          <div class="wwa__item">
            <div>
              <span>12</span>
              <p>{{ $t('whoWeAre.years') }}</p>
            </div>
            <p>{{ $t('whoWeAre.projectManagement') }}</p>
          </div>
          <div class="wwa__item">
            <div>
              <span>8</span>
              <p>{{ $t('whoWeAre.years') }}</p>
            </div>
            <p>{{ $t('whoWeAre.propertyTech') }}</p>
          </div>
          <div class="wwa__item">
            <div>
              <span>17</span>
              <p>{{ $t('whoWeAre.years') }}</p>
            </div>
            <p>{{ $t('whoWeAre.propertyMarketing') }}</p>
          </div>
          <div class="wwa__item">
            <div>
              <span>13</span>
              <p>{{ $t('whoWeAre.years') }}</p>
            </div>
            <p>{{ $t('whoWeAre.researchAndAnalytics') }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'WhatWeAre'
}
</script>

<style lang="scss">
.wwa {
  padding: 130px 0 20px 0;
  min-height: 100vh;
  background-image: url("~@/assets/images/wwa-bg.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @media (max-width: 1024px) {
    padding-top: 90px;
  }

  &__content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  &__left {
    padding-right: 20px;
    max-width: 530px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    grid-gap: 80px;

    @media (max-width: 1440px) {
      grid-gap: 40px;
    }

    @media (max-width: 768px) {
      grid-gap: 20px;
      max-width: 768px;
      padding: 0 0 40px 0;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 100px;
    line-height: 130%;
    color: #FFFFFF;

    @media (max-width: 1440px) {
      font-size: 70px;
    }

    @media (max-width: 768px) {
      font-size: 40px;
    }
  }

  &__subtitle {
    display: flex;
    flex-direction: column;
    grid-gap: 1em;
    font-size: 30px;
    line-height: 39px;
    color: #FFFFFF;

    @media (max-width: 768px) {
      font-size: 24px;
      line-height: 31px;
    }
  }

  &__separator {
    width: 1px;
    border-left: 1px solid #FFFFFF;

    @media (max-width: 768px) {
      width: 100%;
      height: 1px;
      border-left: none;
      border-bottom: 1px solid #fff;
    }
  }

  &__right {
    padding-left: 20px;
    max-width: 560px;
    width: 100%;
    display: flex;
    flex-direction: column;
    grid-gap: 40px;

    @media (max-width: 768px) {
      max-width: 100%;
      padding: 40px 0 0 0;
    }

    h2 {
      max-width: 490px;
      width: 100%;
      font-size: 64px;
      line-height: 130%;
      letter-spacing: 0.01em;
      color: #fff;

      @media (max-width: 1440px) {
        font-size: 50px;
      }

      @media (max-width: 768px) {
        font-size: 40px;
      }

      span {
        color: #414BB2;
      }
    }
    >div {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      grid-gap: 40px;

      @media (max-width: 1440px) {
        grid-gap: 20px;
      }

      @media (max-width: 768px) {
        grid-template-columns: repeat(auto-fit, minmax(150px, 48%));
        grid-gap: 10px;
      }
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    grid-gap: 20px;

    >div {
      display: flex;
      align-items: flex-end;
      grid-gap: 20px;

      p {
        font-weight: 500;
        font-size: 24px;
        line-height: 31px;
        color: #FFFFFF;
      }
    }

    span {
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #414BB2;
      border-radius: 4px;
      font-weight: 700;
      font-size: 64px;
      line-height: 130%;
      letter-spacing: 0.01em;
      color: #FFFFFF;
    }

    p {
      font-size: 24px;
      line-height: 31px;
      color: #FFFFFF;

      @media (max-width: 768px) {
        font-size: 20px;
        line-height: 26px;
      }
    }
  }
}

</style>
