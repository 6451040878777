<template>
  <i class="icon" :class="['fontIcon-' + type, className]"></i>
</template>

<script>
export default {
  name: 'icon',
  props: {
    type: {
      type: String
    },
    up: {
      type: Boolean
    },
    down: {
      type: Boolean
    }
  },
  computed: {
    className () {
      return {
        'icon--up': this.up,
        'icon--down': this.down
      }
    }
  }
}
</script>

<style lang="scss">
.icon {
}
</style>
