<template>
  <div class="home">
    <div class="container">
      <div class="home__content">
        <h2 class="home__subtitle">
          {{ $t('home.weChangeTourism') }}
        </h2>
        <h1 class="home__title">
          {{ $t('home.weAreVnf') }}
        </h1>
        <p class="home__desc">{{ $t('home.protechSolutionForLandlords') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Main"
}
</script>

<style lang="scss">
.home {
  padding: 130px 0 20px 0;
  min-height: 100vh;
  background-image: url("~@/assets/images/home-bg.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @media (max-width: 1024px) {
    padding-top: 90px;
  }
  &__content {
    max-width: 695px;
    width: 100%;
  }
  &__title {
    font-weight: 700;
    font-size: 100px;
    line-height: 130%;
    color: #FFFFFF;

    @media (max-width: 1440px) {
      font-size: 64px;
    }
    @media (max-width: 768px) {
      font-size: 40px;
      margin-bottom: 20px;
    }
  }
  &__subtitle {
    font-weight: 700;
    font-size: 64px;
    line-height: 130%;
    letter-spacing: 0.01em;
    color: #FFFFFF;

    @media (max-width: 1440px) {
      font-size: 40px;
    }
    @media (max-width: 768px) {
      font-size: 30px;
      margin-bottom: 10px;
    }
  }
  &__desc {
    font-weight: 400;
    font-size: 30px;
    line-height: 39px;
    color: #FFFFFF;

    @media (max-width: 768px) {
      font-size: 24px;
      line-height: 31px;
    }
  }
}
</style>
