<template>
  <div class="top-profits">
    <div class="top-profits__left">
      <p>{{ $t('vision.aLongTermRental') }}</p>
      <ul class="top-profits__list">
        <li>{{ $t('vision.fixedIncomeNoMatter') }}</li>
        <li>{{ $t('vision.guaranteedRoi') }}</li>
        <li>{{ $t('vision.noAdditional') }}</li>
        <li>{{ $t('vision.yearsContract') }}</li>
        <li>{{ $t('vision.quarterlySemiAnnualy') }}</li>
        <li>{{ $t('vision.governmentRegistered') }}</li>
        <li>{{ $t('vision.propertyUpgrade') }}</li>
      </ul>
    </div>
    <div class="top-profits__separator"></div>
    <div class="top-profits__right">
      <p>{{ $t('vision.youCanChooseBetween') }}</p>
      <div class="top-profits__graph">
        <div>
          <img src="~@/assets/images/top-profits-graph.webp" alt="">
        </div>
      </div>
      <div class="top-profits__desc">
        <div>
          <span></span>
          <p>{{ $t('vision.vnfOperationalIncome') }}</p>
        </div>
        <div>
          <span></span>
          <p>{{ $t('vision.landlordFixedIncome') }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TopProfits'
}
</script>

<style lang="scss">
.top-profits {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
  }
  &__left {
    padding-right: 20px;
    max-width: 380px;
    width: 100%;

    @media (max-width: 768px) {
      max-width: 100%;
      padding: 0 0 55px 0;
    }

    p {
      margin-bottom: 54px;
      font-weight: 400;
      font-size: 20px;
      line-height: 26px;
      color: #313E47;
    }
  }

  &__list {
    margin-left: 20px !important;
    display: flex;
    flex-direction: column;
    grid-gap: 1em;
    list-style: disc !important;
    li {
      font-weight: 400;
      font-size: 20px;
      line-height: 26px;
      color: #313E47;
    }
  }

  &__separator {
    width: 1px;
    border-left: 1px solid #95A3AE;

    @media (max-width: 768px) {
      width: 100%;
      height: 1px;
      border-left: none;
      border-bottom: 1px solid #95A3AE;
    }
  }

  &__right {
    padding-left: 20px;
    max-width: 755px;
    width: 100%;

    @media (max-width: 768px) {
      max-width: 100%;
      padding: 55px 0 0 0;
    }

    p {
      margin-bottom: 40px;
      font-size: 20px;
      line-height: 26px;
      color: #313E47;
    }
  }

  &__graph {
    @media (max-width: 768px) {
      overflow-x: auto;
      >div {
        min-width: 755px;
      }
    }
  }

  &__desc {
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    grid-gap: 40px;
    @media (max-width: 1024px) {

      align-items: flex-start;
    }
    >div {
      display: flex;
      flex-direction: row;
      align-items: center;
      grid-gap: 15px;

      span {
        width: 40px;
        height: 40px;
      }

      p {
        margin: 0;
        font-size: 20px;
        line-height: 26px;
        color: #313E47;
      }

      &:first-child {
        span {
          background: #41B270;
        }
      }

      &:last-child {
        span {
          background: #414BB2;
        }
      }
    }
  }
}
</style>
