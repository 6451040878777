<template>
  <div
    class="dropdown"
    :class="optionsVisible ? 'active' : ''"
    @focusout="optionsVisible = false"
  >
    <div
      class="dropdown__title"
      @click.prevent="optionsVisible = !optionsVisible"
    >
      <input :value="selectedKey" v-bind="$attrs" :readonly="true" />
      <Icon :type="'down-arrow'" />
    </div>
    <transition name="fade">
      <div class="dropdown__list" v-if="optionsVisible">
        <div
          class="dropdown__item"
          v-for="option in options"
          :key="option.value"
          @click.prevent="selectOption(option)"
        >
          {{ option.name }}
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Icon from "@/components/blocks/Icon.vue";

export default {
  name: "Dropdown",
  components: { Icon },
  props: {
    options: {
      type: Array,
      default() {
        return [];
      },
    },
    isActive: {
      type: Boolean,
      default: true,
    },
    modelValue: [String, Number],
  },
  data() {
    return {
      optionsVisible: false,
    };
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    selectedKey() {
      // return this.options.find((item) => item.value === this.value)?.name;
      return this.value;
    },
  },
  methods: {
    selectOption(option) {
      this.value = option.name;
      this.optionsVisible = false;
    },
  },
};
</script>

<style lang="scss">
.dropdown {
  position: relative;
  &.active {
    .dropdown__title {
      i {
        transform: rotate(180deg);
      }
    }
  }
  &--error {
    .dropdown__title {
      border-color: #f12323;
    }
  }
  &__title {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    line-height: 18px;
    color: #313e47;
    background: #ffffff;
    border-radius: 4px;
    input {
      padding: 15px 20px;
      border: 1px solid #414bb2;
      width: 100%;
    }
    i {
      right: 5px;
      position: absolute;
      font-size: 20px;
      color: #313e47;
      transition: all 0.2s ease-in;
    }
  }

  &__list {
    position: absolute;
    top: 54px;
    width: 100%;
    height: auto;
    background: #ffffff;
    border: 1px solid #414bb2;
    border-radius: 4px;
  }

  &__item {
    padding: 10px;
    transition: all 0.2s ease-in;
    &:hover {
      background: rgba(0, 0, 0, 0.2);
    }
  }
}
</style>
