<template>
  <div class="popup-wrapper" @click.self="$emit('close-popup')">
    <div class="popup" @click.stop>
      <div class="popup__content">
        <Button class="popup__close" @click="$emit('close-popup')">
          <Icon :type="'close'" />
        </Button>
        <h3 class="popup__title">{{ $t("header.request") }}</h3>
        <form action="/send" method="POST">
          <label
            :class="{
              'has-error':
                v$.formData.name.$errors !== 0 && v$.formData.name.$errors[0],
            }"
          >
            <span>{{ $t("popup.name") }}</span>
            <input
              type="text"
              :placeholder="$t('popup.name')"
              required
              v-model="formData.name"
              @blur="v$.formData.name.$touch"
            />
          </label>
          <label
            class="input--error-text"
            :class="{
              'has-error':
                v$.formData.phone.$errors !== 0 && v$.formData.phone.$errors[0],
            }"
          >
            <span>{{ $t("popup.telephone") }}</span>
            <input
              type="tel"
              :placeholder="$t('popup.telephone')"
              required
              v-model="formData.phone"
              @blur="v$.formData.phone.$touch"
            />
            <span
              class="input-error"
              v-if="
                v$.formData.phone.$errors !== 0 &&
                v$.formData.phone.numeric.$invalid
              "
            >
              {{ $t("validations.phone") }}
            </span>
          </label>
          <label
            :class="{
              'has-error':
                v$.formData.email.$errors !== 0 && v$.formData.email.$errors[0],
            }"
            class="input--error-text"
          >
            <span>{{ $t("popup.email") }}</span>
            <input
              type="email"
              :placeholder="$t('popup.email')"
              required
              v-model="formData.email"
              @blur="v$.formData.email.$touch"
            />
            <span
              class="input-error"
              v-if="
                v$.formData.email.$errors !== 0 &&
                v$.formData.email.email.$invalid
              "
            >
              {{ $t("validations.email") }}
            </span>
          </label>
          <label
            :class="{
              'has-error':
                v$.formData.question.$errors !== 0 &&
                v$.formData.question.$errors[0],
            }"
          >
            <span>{{ $t("popup.subjectOfTheQuestion") }}</span>
            <Dropdown
              :options="options"
              :placeholder="$t('popup.requestForPayment')"
              v-model="formData.question"
              @blur="v$.formData.question.$touch"
            />
          </label>
          <label>
            <span>{{ $t("popup.urMessage") }}</span>
            <textarea :placeholder="$t('popup.text')" v-model="formData.text" />
          </label>
          <Button
            class="btn_blue"
            :is-disabled="isBtnDisabled"
            @click.prevent="sendForm"
            type="submit"
          >
            {{ $t("popup.send") }}
          </Button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Dropdown from "@/components/blocks/Dropdown.vue";
import Button from "@/components/blocks/Button.vue";
import Icon from "@/components/blocks/Icon.vue";
import useVuelidate from "@vuelidate/core";
import { required, email, numeric } from "@vuelidate/validators";
import axios from "axios";

export default {
  name: "RequestPopup",
  components: { Icon, Button, Dropdown },
  setup() {
    return {
      v$: useVuelidate({ $lazy: true }),
    };
  },
  data() {
    return {
      formData: {
        name: "",
        phone: "",
        email: "",
        question: "",
        text: "",
      },
      options: [
        {
          name: this.$t("popup.requestForCalculation"),
          value: 1,
        },
        {
          name: this.$t("popup.commercialOffer"),
          value: 2,
        },
        {
          name: this.$t("popup.other"),
          value: 3,
        },
      ],
      isBtnDisabled: false,
    };
  },
  validations() {
    return {
      formData: {
        name: { required },
        phone: { required, numeric },
        email: { required, email },
        question: { required },
      },
    };
  },
  methods: {
    async sendForm() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) {
        return;
      }

      let payload = new FormData();
      Object.entries(this.formData).forEach((key) => {
        if (key[1]) {
          payload.append(key[0], this.formData[key[0]]);
        }
      });
      await axios.post(`${process.env.VUE_APP_BACKEND_URL}/send`, payload);
      this.$emit("close-popup");
    },
  },
};
</script>

<style lang="scss">
.popup-wrapper {
  padding: 0 20px;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 6;
  background: rgba(0, 0, 0, 0.6);

  &.right {
    background: transparent !important;
    .popup {
      position: absolute;
      right: 16%;

      @media (max-width: 1140px) {
        position: relative;
        right: auto;
      }
    }
  }

  &.left {
    .popup {
      position: absolute;
      left: 20%;

      @media (max-width: 1140px) {
        position: relative;
        left: auto;
      }
    }
  }
}

.popup {
  position: relative;
  padding: 40px 40px 45px 40px;
  max-width: 530px;
  width: 100%;
  max-height: 100%;
  background: #ffffff;
  border-radius: 4px;
  overflow: auto;
  @media (max-width: 440px) {
    padding: 20px 20px 40px 20px;
  }
  &__close {
    position: absolute;
    top: 20px;
    right: 20px;
    color: #414bb2;
    font-size: 24px;
  }
  &__title {
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 30px;
    line-height: 39px;
    color: #313e47;
  }
  &__desc {
    font-size: 16px;
    line-height: 21px;
    color: #95a3ae;
  }
  form {
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    label {
      display: flex;
      flex-direction: column;
      grid-gap: 5px;
      .input-error {
        position: absolute;
        font-size: 12px;
        top: calc(100% + 4px);
        line-height: 1;
      }

      &.has-error {
        span {
          color: #f12323;
        }
        input,
        textarea {
          border: 1px solid #f12323;
        }
        .dropdown {
          &__title,
          &__list {
            border-color: #f12323;
          }
        }
      }

      span {
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        color: #313e47;
      }
      input {
        padding: 16px 20px;
        background: #ffffff;
        border: 1px solid #414bb2;
        border-radius: 4px;
        outline: none;
        font-family: "DM Sans", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #313e47;
        &::placeholder {
          font-family: "DM Sans", sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          color: #95a3ae;
        }
      }
      textarea {
        padding: 15px 20px;
        width: 100%;
        height: 120px;
        resize: none;
        background: #ffffff;
        border: 1px solid #414bb2;
        border-radius: 4px;
        outline: none;
      }
    }

    button {
      max-width: 140px;
      width: 100%;
    }
  }
}
.input--error-text {
  position: relative;
}
</style>
