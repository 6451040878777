<template>
<div class="stable">
  <div class="stable__left">
    <p>{{ $t('vision.aPropertyManagement') }}</p>
    <ul class="stable__list">
      <li>{{ $t('vision.splitOfNet') }}</li>
      <li>{{ $t('vision.monthlyPayouts') }}</li>
      <li>{{ $t('vision.transparentAndDetailed') }}</li>
      <li>{{ $t('vision.dataDrivenSolution') }}</li>
      <li>{{ $t('vision.channelsMarketingSupport') }}</li>
      <li>{{ $t('vision.topQuality') }}</li>
    </ul>
  </div>
  <div class="stable__separator"></div>
  <div class="stable__right">
    <p>{{ $t('vision.youCanChooseBetween') }}</p>
    <div class="stable__graph">
      <div>
        <img src="~@/assets/images/stable-graph.webp" alt="">
      </div>
    </div>
    <div class="stable__desc">
      <div>
        <span></span>
        <p>{{ $t('vision.landlordInflow') }}</p>
      </div>
      <div>
        <span></span>
        <p>{{ $t('vision.vnfServiceFree') }}</p>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'StableIncome'
}
</script>

<style lang="scss">
.stable {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
  }
  &__left {
    padding-right: 20px;
    max-width: 380px;
    width: 100%;

    @media (max-width: 768px) {
      max-width: 100%;
      padding: 0 0 55px 0;
    }

    p {
      margin-bottom: 54px;
      font-weight: 400;
      font-size: 20px;
      line-height: 26px;
      color: #313E47;
    }
  }

  &__list {
    margin-left: 20px !important;
    display: flex;
    flex-direction: column;
    grid-gap: 1em;
    list-style: disc !important;
    li {
      font-weight: 400;
      font-size: 20px;
      line-height: 26px;
      color: #313E47;
    }
  }

  &__separator {
    width: 1px;
    border-left: 1px solid #95A3AE;

    @media (max-width: 768px) {
      width: 100%;
      height: 1px;
      border-left: none;
      border-bottom: 1px solid #95A3AE;
    }
  }

  &__right {
    padding-left: 20px;
    max-width: 755px;
    width: 100%;

    @media (max-width: 768px) {
      max-width: 100%;
      padding: 55px 0 0 0;
    }

    p {
      margin-bottom: 40px;
      font-size: 20px;
      line-height: 26px;
      color: #313E47;
    }
  }

  &__graph {
    @media (max-width: 768px) {
      overflow-x: auto;
      >div {
        min-width: 755px;
      }
    }
  }

  &__desc {
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    grid-gap: 40px;
    @media (max-width: 1024px) {

      align-items: flex-start;
    }
    >div {
      display: flex;
      flex-direction: row;
      align-items: center;
      grid-gap: 15px;

      span {
        width: 40px;
        height: 40px;
      }

      p {
        margin: 0;
        font-size: 20px;
        line-height: 26px;
        color: #313E47;
      }

      &:first-child {
        span {
          background: #41B270;
        }
      }

      &:last-child {
        span {
          background: #414BB2;
        }
      }
    }
  }
}
</style>
