<template>
  <header class="header">
    <div>
      <a href="#main" class="header__logo" aria-label="Main logo"></a>
      <nav class="header__nav">
        <a href="#whoWeAre" class="header__nav-wwa">{{ $t('header.whoWeAre') }}</a>
        <a href="#vision" class="header__nav-vision">{{ $t('header.vision') }}</a>
        <a href="#whatWeDo" class="header__nav-wwd">{{ $t('header.whatWeDo') }}</a>
        <a href="#benefits" class="header__nav-benefits">{{ $t('header.benefits') }}</a>
        <a href="#contacts" class="header__nav-contacts">{{ $t('header.contacts') }}</a>
      </nav>
      <transition name="fade">
        <HeaderMenu v-if="sidebarOpen" @close-sidebar="toggleSidebar" is-open/>
      </transition>
      <div class="header__buttons">
        <a href="tel:+971551234455">+971 55 123 44 55</a>
        <Button class="btn_green" aria-label="Change Language" @click="switchLang()">En/Ru</Button>
        <Button class="btn_blue header__request" aria-label="Send Request" @click="$emit('show-popup')">{{ $t('header.request') }}</Button>
      </div>
      <Button class="btn_blue header__open" aria-label="Open menu" @click="toggleSidebar">
        <Icon :type="'list-text'"/>
      </Button>
    </div>
  </header>
</template>

<script>
import Button from '@/components/blocks/Button.vue'
import HeaderMenu from '@/components/header/HeaderMenu.vue'
import Icon from '@/components/blocks/Icon.vue'

export default {
  name: 'Header',
  components: { Icon, HeaderMenu, Button },
  props: {
    switchLangProp: {
      type: Function,
      required: true
    }
  },
  data () {
    return {
      sidebarOpen: false
    }
  },
  methods: {
    toggleSidebar () {
      this.sidebarOpen = !this.sidebarOpen
      document.body.classList.add('no-scroll')
    },
    switchLang () {
      this.switchLangProp()
    }
  }
}
</script>

<style lang="scss">
.header {
  width: 100%;
  margin: 0 auto;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  padding: 30px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in;
  background: #000000;
  z-index: 3;
  @media (max-width: 1440px) {
    padding: 20px;
  }
  @media (max-width: 1024px) {
    padding: 10px 20px 20px 20px;
  }
  >div {
    max-width: 1300px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  &.header-class-home {
    background: #000000;
    transition: all 0.2s ease-in;
  }
  &.header-class-whoWeAre {
    background: #000000;
    transition: all 0.2s ease-in;
    .header__nav {
      .header__nav-wwa {
        color: #414BB2 !important;
      }
    }
  }
  &.header-class-whatWeDo {
    background: #000000;
    transition: all 0.2s ease-in;

    .header__nav {
      .header__nav-wwd {
        color: #414BB2 !important;
      }
    }
  }
  &.header-class-vision {
    background: #FFFFFF;
    transition: all 0.2s ease-in;

    .header__nav {
      a {
        color: #313E47;
      }
      .header__nav-vision {
        color: #414BB2 !important;
      }
    }
    .header__buttons {
      a {
        color: #313E47;
      }
    }
    .header__logo {
      background-image: url('~@/assets/images/logo-black.svg');
    }
  }
  &.header-class-benefits {
    background: #000000;
    transition: all 0.2s ease-in;
    .header__nav {
      .header__nav-benefits {
        color: #414BB2 !important;
      }
    }
  }
  &.header-class-contacts {
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s ease-in;
    @media (max-width: 768px) {
      opacity: 1;
      visibility: visible;
      background: #FFFFFF;

      .header__nav {
        a {
          color: #313E47;
        }
      }
      .header__buttons {
        a {
          color: #313E47;
        }
      }
      .header__logo {
        background-image: url('~@/assets/images/logo-black.svg');
      }
    }
  }

  &__logo {
    width: 82.5px;
    height: 30px;
    background: url('~@/assets/images/logo.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    transition: all 0.2s ease-in;
    @media (max-width: 1024px) {
      width: 68px;
      height: 25px;
    }
  }
  &__nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 30px;

    @media (max-width: 1120px) {
      grid-gap: 20px;
    }

    @media (max-width: 1024px) {
      display: none;
    }

    a {
      font-weight: 500;
      font-size: 18px;
      line-height: 23px;
      color: #FFFFFF;
      transition: all .2s ease-in;
      &:hover {
        color: #414BB2 !important;
      }
      &.router-link-active {
        color: #414BB2 !important;
      }
    }
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 20px;

    @media (max-width: 1120px) {
      grid-gap: 15px;
    }

    @media (max-width: 1024px) {
      margin-left: auto;
      grid-gap: 10px;
    }

    a {
      font-weight: 700;
      font-size: 20px;
      line-height: 100%;
      color: #FFFFFF;
      transition: all 0.2s ease-in;
      @media (max-width: 1120px) {
        font-size: 16px;
      }

      @media (max-width: 1024px) {
        display: none;
      }
    }

    button {
      @media (max-width: 1024px) {
        padding: 10px 17px !important;
      }
    }
  }

  &__request {
    width: 120px !important;
    padding: 10px !important;
    text-align: center;
  }

  &__open {
    margin-left: 18px;
    display: none;
    padding: 10px !important;
    border-radius: 50% !important;
    width: 40px;
    height: 40px;
    i {
      font-size: 20px;
    }
    @media (max-width: 1024px) {
      display: block;
    }
  }
}
</style>
