<template>
<div class="benefits">
  <div class="container">
    <div class="benefits__content">
      <div class="benefits__top">
        <h1 class="benefits__title">{{ $t('benefits.benefits') }}</h1>
        <p class="benefits__desc">{{ $t('benefits.whyWouldLandlord') }}</p>
      </div>
      <div class="benefits__bottom">
        <div class="benefits__item">
          <span>1</span>
          <p>{{ $t('benefits.passionateEuropean') }}</p>
        </div>
        <div class="benefits__item">
          <span>2</span>
          <p>{{ $t('benefits.varietyOfManagement') }}</p>
        </div>
        <div class="benefits__item">
          <span>3</span>
          <p>{{ $t('benefits.transparentAndTrustful') }}</p>
        </div>
        <div class="benefits__item">
          <span>4</span>
          <p>{{ $t('benefits.talorMadeStrategies') }}</p>
        </div>
        <div class="benefits__item">
          <span>5</span>
          <p>{{ $t('benefits.europeanApproach') }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'Benefits',
}
</script>

<style lang="scss">
.benefits {
  padding: 130px 0 20px 0;
  min-height: 100vh;
  background-image: url("~@/assets/images/benefits-bg.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @media (max-width: 1024px) {
    padding-top: 90px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    grid-gap: 80px;

    @media (max-width: 1024px) {
      grid-gap: 0;
    }
  }

  &__top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    grid-gap: 40px;

    @media (max-width: 1024px) {
      padding-bottom: 40px;
      flex-direction: column;
      align-items: flex-start;
      grid-gap: 20px;
      border-bottom: 1px solid #FFFFFF;;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 100px;
    line-height: 130%;
    color: #FFFFFF;

    @media (max-width: 1440px) {
      font-size: 70px;
    }

    @media (max-width: 768px) {
      font-size: 40px;
    }
  }

  &__desc {
    max-width: 535px;
    width: 100%;
    font-size: 30px;
    line-height: 39px;
    color: #FFFFFF;

    @media (max-width: 1024px) {
      max-width: 100%;
    }

    @media (max-width: 768px) {
      font-size: 24px;
      line-height: 31px;
    }
  }

  &__bottom {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 80px;
    grid-row-gap: 40px;

    @media (max-width: 1024px) {
      padding-top: 40px;
    }

    @media (max-width: 650px) {
      grid-template-columns: repeat(auto-fit, minmax(240px, 100%));
    }

    .benefits-item {
      &:nth-child(2) {
        grid-column: 1/1;
        grid-row: 2/2;
      }
      &:nth-child(3) {
        grid-column: 1/1;
        grid-row: 3/3;
      }
      &:nth-child(4) {
        grid-column: 2/2;
        @media (max-width: 650px) {
          grid-column: 1/1;
        }
      }
      &:nth-child(5) {
        grid-column: 2/2;
        @media (max-width: 650px) {
          grid-column: 1/1;
        }
      }
    }
  }

  &__item {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 30px;

    span {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      width: 60px;
      height: 103px;
      background: #414BB2;
      border-radius: 4px;
      font-weight: 700;
      font-size: 64px;
      line-height: 130%;
      letter-spacing: 0.01em;
      color: #FFFFFF;
    }

    p {
      font-size: 26px;
      line-height: 34px;
      color: #FFFFFF;

      @media (max-width: 768px) {
        font-size: 20px;
        line-height: 24px;
      }
    }
  }
}
</style>
