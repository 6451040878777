<template>
  <div class="vision">
    <div class="container">
      <div class="vision__content">
        <h1 class="vision__title">{{ $t('vision.solution') }}</h1>
        <div class="vision__btns">
          <div
              :class="{ 'active': activeTab === 0 }"
              @click="changeTab(0)"
          >
            {{ $t('vision.iWantSafe') }}
          </div>
          <p>{{ $t('vision.or') }}</p>
          <div
              :class="{ 'active': activeTab === 1 }"
              @click="changeTab(1)"
          >
            {{ $t('vision.iSeekForTopProfits') }}
          </div>
        </div>
        <div class="vision__tab-content">
          <component :is="tabs[activeTab].component"></component>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StableIncome from "@/components/vision/StableIncome.vue";
import TopProfits from "@/components/vision/TopProfits.vue";

export default {
  name: 'Vision',
  components: {
  },
  data() {
    return {
      tabs: [
        { component: StableIncome },
        { component: TopProfits }
      ],
      activeTab: 0
    };
  },
  methods: {
    changeTab(index) {
      this.activeTab = index;
    }
  }
}
</script>


<style lang="scss">
  .vision {
    padding: 130px 0 20px 0;
    min-height: 100vh;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @media (max-width: 1024px) {
      padding-top: 90px;
    }
    &__content {}
    &__title {
      margin-bottom: 20px;
      font-weight: 700;
      font-size: 64px;
      line-height: 130%;
      letter-spacing: 0.01em;
      color: #313E47;
      @media (max-width: 1440px) {
        font-size: 40px;
      }
      @media (max-width: 768px) {
        text-align: center;
      }
    }
    &__btns {
      margin-bottom: 80px;
      display: flex;
      flex-direction: row;
      align-items: center;
      grid-gap: 20px;

      @media (max-width: 1440px) {
        margin-bottom: 40px;
      }
      @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
      }

      p {
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        color: #313E47;
      }

      >div {
        margin: 5px 0;
        width: 350px;
        padding: 16px;
        font-weight: 500;
        font-size: 20px;
        line-height: 26px;
        color: #313E47;
        border: 1px solid #414BB2;
        border-radius: 30px;
        text-align: center;
        cursor: pointer;
        transition: all 0.2s ease-in;
        &:hover {
          opacity: 0.8;
        }
        @media (max-width: 1440px) {
          padding: 10px;
        }

        &.active {
          margin: 0;
          padding: 21px;
          background: #414BB2;
          border-radius: 35px;
          color: #FFFFFF;

          @media (max-width: 1440px) {
            padding: 15px;
          }
        }
      }
    }
  }
</style>
