<template>
  <button
      class="btn"
      :class="{
     'button-disabled': isDisabled,
     'b-btn--loading': isLoading,
    }"
      :aria-label="label"
      @click="handleClick"
  >
    <slot></slot>
    {{ label }}
  </button>
</template>

<script>
export default {
  name: 'Button',
  props: {
    label: {
      type: String
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleClick () {
      if (!this.isDisabled && !this.isLoading) this.$emit('protected-click')
    }
  }
}
</script>

<style scoped lang="scss">
.btn {
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  border-radius: 20px;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.2s ease-in;
  &:hover {
    opacity: 0.8;
  }
  &_green {
    padding: 10px 27px;
    font-size: 16px;
    line-height: 21px;
    background: #41B270;
    color: #FFFFFF;
  }
  &_blue {
    padding: 10px 30px;
    font-size: 16px;
    line-height: 21px;
    background: #414BB2;
    color: #FFFFFF;
  }
  &_transparent {
    padding: 23px;
    font-size: 20px;
    line-height: 26px;
    border: 1px solid #414BB2;
    background: transparent;
  }
}
.button-disabled {
  opacity: 0.8;
  pointer-events: none;
  &.b-btn--red-light:hover {
    color: #242b33;
    background-color: #fee8e6;
    cursor: default;
  }
  &.b-btn--red-light:active {
    color: #242b33;
    background-color: #fee8e6;
  }
}
</style>
