<template>
  <div class="app-content" :class="textSizeClass">
    <Header
        :class="headerClass"
        @show-popup="openPopup"
        :switchLangProp="switchLang"
    />
    <HomeView @show-popup="openPopup" />
    <transition name="fade">
      <RequestPopup
          v-if="isPopupVisible"
          @close-popup="closePopup"
      />
    </transition>
  </div>
</template>

<script>
import Header from '@/components/blocks/Header.vue'
import RequestPopup from '@/components/popups/RequestPopup.vue'
import { useI18n } from 'vue-i18n'
import HomeView from "@/views/HomeView.vue";

export default {
  components: {
    HomeView,
    Header,
    RequestPopup
  },
  setup() {
    const { t, locale } = useI18n({ useScope: 'global' })
    const switchLang = () => {
      locale.value = locale.value === 'en' ? 'ru' : 'en'
      localStorage.setItem('lang', locale.value)
    }
    return { t, switchLang }
  },
  data() {
    return {
      isPopupVisible: false,
      headerClass: 'default',
    }
  },
  computed: {
    textSizeClass() {
      return this.$i18n.locale === 'ru' ? 'text-size--ru' : 'text-size--en'
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    openPopup() {
      this.isPopupVisible = true
      document.body.classList.add('no-scroll');
    },
    closePopup() {
      this.isPopupVisible = false
      document.body.classList.remove('no-scroll');
    },
    handleScroll() {
      const blocks = document.querySelectorAll('.home, .benefits, .wwd, .contacts, .wwa, .vision');

      const scrollPosition = window.scrollY + 100 || window.pageYOffset;

      let currentBlock = null;
      blocks.forEach((block) => {
        const blockTop = block.offsetTop;
        const blockHeight = block.offsetHeight;

        if (scrollPosition >= blockTop && scrollPosition < blockTop + blockHeight) {
          currentBlock = block;
        }
      });

      if (currentBlock) {
        const blockClass = currentBlock.dataset.headerClass;
        this.headerClass = blockClass;
      } else {
        this.headerClass = 'default';
      }
    }
  }
}
</script>


<style lang="scss">
@import "~@/scss/main.scss";
.text-size--ru {
  .home__subtitle {
    font-size: 56px;

    @media (max-width: 1440px) {
      font-size: 40px;
    }
    @media (max-width: 769px) {
      font-size: 30px;
    }
  }
  .home__desc,
  .wwa__subtitle {
    font-size: 24px;
    line-height: 31px;
  }
  .wwa__title,
  .benefits__title {
    font-size: 64px;

    @media (max-width: 768px) {
      font-size: 40px;
    }
  }

  .wwa__item {
    p:last-child {
      @media (max-width: 768px) {
        font-size: 16px;
      }
    }
  }

  .wwa__right h2 {
    font-size: 56px;

    @media (max-width: 1440px) {
      font-size: 50px;
    }
    @media (max-width: 768px) {
      font-size: 40px;
    }
  }
}

.text-size--en {
  font-size: 14px !important;
}
</style>
