<template>
<div class="wwd">
  <div class="wwd__content">
    <div class="wwd__item">
      <div class="wwd__item-text">
        <h2>{{ $t('whatWeDo.outstandingDesign') }}</h2>
        <p>{{ $t('whatWeDo.VnfImpliesDesign') }}</p>
      </div>
    </div>
    <div class="wwd__item">
      <div class="wwd__item-text">
        <h2>{{ $t('whatWeDo.outstandingComfort') }}</h2>
        <ul>
          <li>{{ $t('whatWeDo.fullyEquipped') }}</li>
          <li>{{ $t('whatWeDo.dishwasher') }}</li>
          <li>{{ $t('whatWeDo.espressoMachine') }}</li>
          <li>{{ $t('whatWeDo.highQualityMatrasses') }}</li>
          <li>{{ $t('whatWeDo.premiumBed') }}</li>
          <li>{{ $t('whatWeDo.ledTvWith') }}</li>
        </ul>
      </div>
    </div>
    <div class="wwd__item">
      <div class="wwd__item-text">
        <h2>{{ $t('whatWeDo.outstandingAmneties') }}</h2>
        <ul>
          <li>{{ $t('whatWeDo.furnishedCozy') }}</li>
          <li>{{ $t('whatWeDo.poolAndGym') }}</li>
          <li>{{ $t('whatWeDo.security') }}</li>
          <li>{{ $t('whatWeDo.HotelAccess') }}</li>
        </ul>
      </div>
    </div>
  </div>
  <p class="wwd__title">{{ $t('whatWeDo.whatWeDo') }}</p>
</div>
</template>

<script>
export default {
  name: 'WhatWeDo'
}
</script>

<style lang="scss">
.wwd {
  position: relative;
  min-height: 100vh;
  &__content {
    position: relative;
    min-height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }
  &__title {
    position: absolute;
    left: 300px;
    bottom: 80px;
    font-weight: 700;
    font-size: 64px;
    line-height: 130%;
    letter-spacing: 0.01em;
    color: #FFFFFF;

    @media (max-width: 1440px) {
      font-size: 40px;
    }

    @media (max-width: 1024px) {
      left: 100px;
    }

    @media (max-width: 768px) {
      position: absolute;
      left: 20px;
      font-size: 40px;
      bottom: 50px;
    }
  }
  &__item {
    padding: 130px 50px 20px 50px;
    position: relative;
    width: 33.333%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    min-height: 100vh;
    transition: all 0.2s ease-in;
    @media (max-width: 1024px) {
      padding: 130px 20px 20px 20px;
    }
    @media (max-width: 768px) {
      width: 100%;
    }
    &::before {
      position: absolute;
      content: '';
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
      transition: all 0.2s ease-in;
      opacity: 1;
      visibility: visible;
    }
    &:hover {
      &::before {
        opacity: 0;
        visibility: hidden;
        transition: all 0.2s ease-in;
      }
      .wwd__item-text {
        opacity: 1;
        visibility: visible;
        transition: all 0.2s ease-in;
      }
    }
    &:first-child {
      background-image: url('~@/assets/images/wwd-img1.webp');
    }
    &:nth-child(2) {
      background-image: url('~@/assets/images/wwd-img2.webp');
    }
    &:last-child {
      background-image: url('~@/assets/images/wwd-img3.webp');
    }
  }
  &__item-text {
    display: flex;
    flex-direction: column;
    grid-gap: 15px;
    opacity: 0;
    visibility: hidden;
    h2 {
      font-weight: 700;
      font-size: 30px;
      line-height: 130%;
      letter-spacing: 0.01em;
      color: #FFFFFF;

      @media (max-width: 1440px) {
        font-size: 24px;
      }
    }
    p,
    li {
      font-weight: 400;
      font-size: 24px;
      line-height: 31px;
      color: #FFFFFF;

      @media (max-width: 1440px) {
        font-size: 16px;
        line-height: 18px;
      }

      @media (max-width: 768px) {
        font-size: 24px;
        line-height: 31px;
      }

      @media (max-width: 414px) {
        font-size: 16px;
        line-height: 18px;
      }
    }
    ul {
      margin: 0 0 0 20px;
      padding: 0;
      display: flex;
      flex-direction: column;
      grid-gap: 1em;
    }
  }
}
</style>
