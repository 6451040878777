<template>
  <div class="header-menu" :class="{ 'sidebar--open': isOpen }">
    <nav class="header-menu__nav">
      <a :href="item.link"
        v-for="(item, index) in linksList"
        :key="index"
        :name="item.name"
        @click="closeSidebar"
      >
        {{ item.name }}
      </a>
    </nav>
    <Button class="header-menu__close" @click="closeSidebar">
      <Icon :type="'close'"/>
    </Button>
  </div>
</template>

<script>
import Button from '@/components/blocks/Button.vue'
import Icon from '@/components/blocks/Icon.vue'

export default {
  name: 'HeaderMenu',
  components: { Icon, Button },
  props: {
    isOpen: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      linksList: [
        {
          name: this.$t('header.whoWeAre'),
          link: '#whoWeAre'
        },
        {
          name: this.$t('header.whatWeDo'),
          link: '#whatWeDo'
        },
        {
          name: this.$t('header.vision'),
          link: '#vision'
        },
        {
          name: this.$t('header.benefits'),
          link: '#benefits'
        },
        {
          name: this.$t('header.contacts'),
          link: '#contacts'
        }
      ]
    }
  },
  methods: {
    closeSidebar () {
      this.$emit('close-sidebar')
      document.body.classList.remove('no-scroll')
    }
  }
}
</script>

<style lang="scss">
.header-menu {
  padding: 55px 20px 20px 30px;
  display: none;
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  width: 70%;
  background: #FFFFFF;
  border-radius: 4px 0 0 4px;
  transition: all 0.2s ease-in;
  z-index: 5;

  @media (max-width: 1024px) {
    display: block;
  }

  &__nav {
    width: 90%;
    grid-gap: 40px;
    display: flex;
    flex-direction: column;

    a {
      font-weight: 500;
      font-size: 18px;
      line-height: 23px;
      color: #313E47;
    }
  }

  &__close {
    position: absolute;
    top: 55px;
    right: 20px;

    i {
      font-size: 20px;
    }
  }
}
</style>
